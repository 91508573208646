<template>
  <div class="messageList">
    <!-- 招标公告 资格预审公告 -->
    <div v-if="types == 1 || types == 2" class="tables">
      <el-table :data="dataList.data" :header-cell-style="headerStyle" :row-style="rowStyle" :key="tableKey">
        <el-table-column width="340px">
          <template slot="header">
            <div class="change-bidding-header">招标名称</div>
            <div class="change-bidding-header">招标编号</div>
          </template>
          <template slot-scope="{row}">
            <div class="oneTbody">
              <div>
                <el-tag size="small" type="danger">招标</el-tag>
              </div>
              <div>
                <div class="elissipis-text" style="width:266px;" :title="row.notice_init && row.notice_init.bidding_name">
                  {{ row.notice_init && row.notice_init.bidding_name }}
                </div>
                <div class="elissipis-text" style="width:266px;">{{ row.notice_init && row.notice_init.serial_number }}
                </div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column>
          <template slot="header">
            <div>招标人</div>
            <div>项目地址</div>
          </template>
          <template slot-scope="{row}">
            <div class="elissipis-text" :title="row.username">{{ row.notice_init && row.notice_init.username }}</div>
            <div>
              <i class="el-icon-location-outline"></i>
              {{ row.notice_init && row.notice_init.project_address_string }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="发布状态" width="180px">
          <template slot-scope="{row}">
            <el-tag size="small" v-if="new Date(row.sign_up_end_time).getTime() > new Date().getTime()">发布中</el-tag>
            <el-tag size="small" type="info" v-else>已结束</el-tag>
          </template>
        </el-table-column>
        <el-table-column width="180px">
          <template slot="header">
            <div>招标类型</div>
            <div>招标方式</div>
          </template>
          <template slot-scope="{row}">
            <div> {{ row.tender_type_name }}</div>
            <div> {{ row.bidding_way == 1 ? "平台公开" : "入围公开" }}</div>
          </template>
        </el-table-column>
        <el-table-column width="180px">
          <template slot="header">
            <div>发布开始时间</div>
            <div>报名截止时间</div>
          </template>
          <template slot-scope="{row}">
            <div> {{ row.release_start_time }}</div>
            <div> {{ row.sign_up_end_time }}</div>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="120px">
          <template slot-scope="scope">
            <span class="tbody" @click="gotoOther(scope.row)">查看详情</span>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- 变更公告 -->
    <div v-if="types == 5">
      <el-table :data="dataList.data" :header-cell-style="headerStyle" :row-style="rowStyle" :key="tableKey">
        <el-table-column width="340px">
          <template slot="header">
            <div class="change-bidding-header">招标名称</div>
            <div class="change-bidding-header">招标编号</div>
          </template>
          <template slot-scope="{row}">
            <div class="oneTbody" style="width:300px;">
              <div>
                <el-tag size="small" type="danger">变更</el-tag>
              </div>
              <div>
                <div class="elissipis-text" style="width:266px;"
                  :title="row.notice && row.notice.notice_init && row.notice.notice_init.bidding_name">
                  {{ row.notice && row.notice.notice_init && row.notice.notice_init.bidding_name }}
                </div>
                <div class="elissipis-text" style="width:266px;">{{ row.notice && row.notice.notice_init &&
                  row.notice.notice_init.serial_number }}</div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column>
          <template slot="header">
            <div>变更名称</div>
            <div>变更编号</div>
          </template>
          <template slot-scope="{row}">
            <div class="elissipis-text" :title="row.change_name">{{ row.change_name }}</div>
            <div>{{ row.change_serial_number }}</div>
          </template>
        </el-table-column>
        <el-table-column>
          <template slot="header">
            <div>招标人</div>
            <div>项目地址</div>
          </template>
          <template slot-scope="{row}">
            <div class="elissipis-text" :title="row.notice && row.notice.notice_init && row.notice.notice_init.username">
              {{ row.notice && row.notice.notice_init && row.notice.notice_init.username }}</div>
            <div>{{ row.notice && row.notice.notice_init && row.notice.notice_init.project_address_string }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="change_release_at" label="发布时间"></el-table-column>
        <el-table-column label="操作" width="120px">
          <template slot-scope="scope">
            <span class="tbody" @click="gotoOther(scope.row)">查看详情</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 中标公告 -->
    <div v-if="types == 6">
      <el-table :data="dataList.data" :header-cell-style="headerStyle" :row-style="rowStyle" :key="tableKey">
        <el-table-column width="340px">
          <template slot="header">
            <p class="change-bidding-header">招标名称</p>
            <p class="change-bidding-header">招标编号</p>
          </template>
          <template slot-scope="{row}">
            <div class="oneTbody">
              <div>
                <el-tag size="small" type="danger">中标</el-tag>
              </div>
              <div>
                <div class="elissipis-text" style="width:266px;" :title="row.notice && row.notice.bidding_name">
                  {{ row.notice && row.notice.bidding_name }}
                </div>
                <div>{{ row.notice && row.notice.serial_number }}</div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column>
          <template slot="header">
            <p>招标人</p>
            <p>项目地址</p>
          </template>
          <template slot-scope="{row}">
            <div class="elissipis-text">{{ row.notice && row.notice.username }}</div>
            <div class="elissipis-text">{{ row.notice && row.notice.project_address_string }}</div>
          </template>
        </el-table-column>
        <el-table-column label="发布状态" width="180px">
          <template slot-scope="{row}">
            <el-tag size="small" v-if="row.award_end_status" type="info">已结束</el-tag>
            <el-tag size="small" v-else>发布中</el-tag>
          </template>
        </el-table-column>
        <el-table-column>
          <template slot="header">
            <p>招标类型</p>
            <p>招标方式</p>
          </template>
          <template slot-scope="{row}">
            <div v-if="row.notice && row.notice.notice_block_set">
              <p>
                <span v-for="(item, index) in row.notice.notice_block_set" :key="item.id">
                  {{ item.tender_type_name }}
                </span>
              </p>
            </div>
            <p v-if="row.notice && row.notice.bidding_way_plus_invite">
              <template v-if="row.notice.bidding_way_plus_invite == 1">
                平台公开
              </template>
              <template v-else-if="row.notice.bidding_way_plus_invite == 1">
                入围公开
              </template>
              <template v-else-if="row.notice.bidding_way_plus_invite == 3">
                邀请
              </template>
              <template v-else-if="row.notice.bidding_way_plus_invite == 4">
                平台公开+邀请
              </template>
              <template v-else-if="row.notice.bidding_way_plus_invite == 4">
                入围公开+邀请
              </template>
              <template v-else>
                平台公开
              </template>
            </p>
          </template>
        </el-table-column>
        <el-table-column>
          <template slot="header">
            <p>公示开始时间</p>
            <p>公示开始时间</p>
          </template>
          <template slot-scope="{row}">
            <p>{{ row.publicity_start_at }}</p>
            <p>{{ row.publicity_end_at }}</p>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="120px">
          <template slot-scope="scope">
            <span class="tbody" @click="gotoOther(scope.row)">查看详情</span>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- 询价公告 -->
    <div v-if="types == 7">
      <el-table size="small" :data="dataList.data" :header-cell-style="headerStyle" :row-style="rowStyle">
        <el-table-column label=" " width="100" align="center">
          <template slot-scope="{row}">
            <el-tag size="small" type="primary" v-if="row.issued == 2">快询</el-tag>
            <el-tag size="small" type="danger" v-else>询价</el-tag>
          </template>
        </el-table-column>
        <el-table-column min-width="140px">
          <template slot="header">
            <div>询价标题</div>
            <div>询价单号</div>
          </template>
          <template slot-scope="{ row }">
            <div class="">
              <div class="text_address" :title="row.title">
                {{ row.title }}
              </div>
              <div class="text_address" :title="row.serial_number">
                {{ row.serial_number }}
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column min-width="270px">
          <template slot="header" slot-scope="scope">
            <div>询价人</div>
            <div>询价方式</div>
          </template>
          <template slot-scope="{ row }">
            <div v-if="row.company_other_info">
              <div class="text_address" :title="row.company_other_info.name">
                {{ row.company_other_info.name }}
              </div>
            </div>
            <div>
              {{ row.inquiry_way_text }}
            </div>
          </template>
        </el-table-column>
        <el-table-column min-width="160px">
          <template slot="header" slot-scope="scope">
            <div>发布状态</div>
          </template>
          <template slot-scope="{ row }">
            <div>
              <el-tag size="mini" v-if="row.inquiry_status == 1" type="info">已结束</el-tag>
              <el-tag size="mini" v-else>发布中</el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column min-width="140px">
          <template slot="header" slot-scope="scope">
            <div>发布开始时间</div>
            <div>发布结束时间</div>
          </template>
          <template slot-scope="{ row }">
            <div>
              {{ row.inquiry_issued_start_time }}
            </div>
            <div>
              {{ row.inquiry_issued_end_time }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" min-width="120px">
          <template slot-scope="scope">
            <span class="tbody" @click="gotoOther(scope.row)">查看详请</span>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="footer">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="dataList.current_page" :page-sizes="[10, 20, 30, 40]" :page-size="10"
        layout="total, sizes, prev, pager, next, jumper" :total="dataList.total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  props: ["dataList", "types"],
  data() {
    return {
      title: "",
      rowStyle: {
        //表格行样式
        color: "#333",
        fontSize: "12px",
        height: "48px",
      },
      headerStyle: {
        //表格头部样式
        color: "#333",
        fontSize: "14px",
        height: "48px",
      },
      tableKey: 0,
    };
  },
  // watch:{
  // 	types(val){
  // 		this.tableKey = + new Date()
  // 	}
  // },
  methods: {
    search() {
      this.$emit("search", this.title);
    },
    endTimeCalc(end_time) {
      return new Date(end_time).getTime() < new Date().getTime();
    },
    handleSizeChange(val) {
      this.$emit("changeSize", { type: "size", num: val });
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      this.$emit("changeSize", { type: "page", num: val });
      console.log(`当前页: ${val}`);
    },
    gotoOther(item) {
      let msg = "";
      if (this.types == 1 || this.types == 2) {
        msg = 1;
        this.$router.push({
          path: "/temlateLook",
          query: { type: msg, id: item.id, company_id: this.$route.query.company_id || 0 },
        });
      } else if (this.types == 5) {
        msg = 5;
        console.log("change", item);
        this.$router.push({
          path: "/temlateLook",
          query: { type: msg, id: item.id, company_id: this.$route.query.company_id || 0 },
        });
      } else if (this.types == 6) {
        msg = 6;
        this.$router.push({
          path: "/temlateLook",
          query: { type: msg, id: item.notice_id, company_id: this.$route.query.company_id || 0 },
        });
      } else if (this.types == 7) {
        msg = 7;
        console.log(item);
        this.$router.push({
          path: "/temlateLook",
          query: { type: msg, id: item.id, types: item.type, company_id: this.$route.query.company_id || 0 },
        });
      }
    },
    statusMsg(val) {
      if (val < 3) {
        return "发布中";
      } else {
        return "已结束";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.messageList {
  min-height: 261px;

  .elissipis-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .header {
    display: flex;
    justify-content: space-between;

    &>div:nth-child(1) {
      line-height: 28px;
    }

    margin-bottom: 20px;
  }

  .change-bidding-header {
    width: 320px;
    box-sizing: border-box;
    padding-left: 70px;
  }
}

.explain-info {
  width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 24px;
}

.btns {
  display: inline-block;
  width: 60px;
  height: 28px;
  line-height: 28px;
  text-align: center;
  border: 1px solid #f26161;
  color: #f26161;
  border-radius: 5px;
  user-select: none;
  cursor: pointer;
}

.text_address {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.text-over {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.tables {
  background-color: #fff;

  .theader {
    display: flex;
    height: 64px;

    &:nth-of-type(even) {
      background-color: #fafafa;
    }

    &>div {
      text-align: center;
      padding: 0 5px;
      box-sizing: border-box;
    }

    &>div:nth-child(1) {
      width: 509px;

      .info {
        width: 420px;
      }

    }

    &>div:nth-child(2) {
      width: 222px;
    }

    &>div:nth-child(3) {
      width: 109px;
    }

    &>div:nth-child(4) {
      width: 130px;
    }

    &>div:nth-child(5) {
      width: 122px;
    }

    &>div:nth-child(6) {
      width: 108px;
    }
  }
}

.alonestatus {
  line-height: 64px;
}

.twicestatus {
  margin-top: 15px;
}

.tbody {
  color: red;
  user-select: none;
  cursor: pointer;
}

.oneTbody {
  display: flex;
  width: 320px;
  padding-left: 30px;

  &>div:nth-child(1) {
    width: 50px;
    position: relative;
    top: 12px;
    box-sizing: border-box;
  }

  &>div:nth-child(2) {
    margin-left: 20px;
    width: 439px;

    &>p:nth-child(1) {
      line-height: 40px;
      text-align: left;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
    }

    &>p:nth-child(2) {
      font-size: 12px;
      text-align: left;
    }
  }
}

.postitons {
  margin-top: 10px;

  &>p {
    font-size: 12px;
    line-height: 20px;
  }
}

.footer {
  background-color: #fff;
  padding: 20px;
  text-align: right;
}</style>
